// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

@use './app/components/toolbar/toolbar-theme' as toolbar;

@use './app/layout/header/side-nav-theme' as sidenav;

@use './app/components/chatbot/chatbot-theme' as chatbot;

@use './app/pages/privacy-data/privacy-data-theme' as privacy-data;

@use './app/pages/support-and-feedback/support-and-feedback-theme' as
    support-and-feedback;

@use './app/dialog/support-and-feedback-dialog/support-and-feedback-dialog-theme'
    as support-and-feedback-dialog;

@use './app/dialog/source-dialog/source-dialog-theme' as source-dialog;

@use './app/components/snackbar/snackbar-theme' as snackbar;

@use 'sass:map';
@use '/public/colors/colors.scss' as colors;

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the theme object.
$OpsCoPilotUI-theme: mat.define-theme(
    (
        typography: (
            bold-weight: 500,
            medium-weight: 200,
            regular-weight: 100,
        ),
        color: (
            theme-type: light,
            primary: mat.$azure-palette,
            tertiary: mat.$blue-palette,
        ),
        density: (
            scale: 0,
        ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($sita-t-plus-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($sita-t-plus-theme);
/* You can add global styles to this file, and also import other style files */
@import '../public/colors/colors.scss';

@import '../public/typography/typography.scss';
@import '../public/spacing/spacing.scss';

/**
 Style for error messages in divs related to API errors.
 */
.api-error-msg {
    padding-top: 5px;
    color: red;
}

/**
 * apply this style when opening snack bar using
 *
 *  panelClass: ['snackbar-error'],
 */
.snackbar-error {
    .mdc-snackbar__surface {
        background-color: red !important;
    }

    .mdc-snackbar__label {
        color: white !important;
    }
}

.snackbar-success {
    .mdc-snackbar__surface {
        /*background-color: var(--color-primary) !important;*/
    }

    .mdc-snackbar__label {
        color: white !important;
    }
}

.mh-10 {
    margin-right: 10px;
    margin-left: 10px;
}

html,
body {
    height: 100%;
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    @include mat.all-component-themes($OpsCoPilotUI-theme);
    @include toolbar.theme($OpsCoPilotUI-theme);
    @include sidenav.sidenav-theme($OpsCoPilotUI-theme);
    @include chatbot.chatbot-theme($OpsCoPilotUI-theme);
    @include privacy-data.theme($OpsCoPilotUI-theme);
    @include support-and-feedback-dialog.theme($OpsCoPilotUI-theme);
    @include support-and-feedback.theme($OpsCoPilotUI-theme);
    @include source-dialog.theme($OpsCoPilotUI-theme);
    @include snackbar.theme($OpsCoPilotUI-theme);

    background: var(--primary-bg);
}
