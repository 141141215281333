@import '../mixings/mixings';

:root,
.dark-theme {
    /* Desktop Typography */
    --font-size-xxxs: 0.7rem; // Tiny
    --font-size-xxs: 0.75rem; // Extra extra small
    --font-size-xs-plus: 0.85rem; // Slightly larger than extra small
    --font-size-xs: 0.875rem; // Extra small
    --font-size-sm: 0.9375rem; // Small
    --font-size-md: 1rem; // Medium (Base)
    --font-size-md-plus: 1.125rem; // Medium plus
    --font-size-lg: 1.25rem; // Large
    --font-size-lg-minus: 1.4rem; // Larger than large, smaller than extra large
    --font-size-xl: 1.5rem; // Extra large
    --font-size-lg-plus: 1.563rem; // Large plus
    --font-size-xxl: 2rem; // Extra extra large
    --font-size-xxl-plus: 2.25rem;
    --font-size-3x: 3rem; // Triple large

    --font-weight-thin: 100; // Thin
    --font-weight-extra-light: 200; // Extra Light
    --font-weight-light: 300; // Light
    --font-weight-regular: 400; // Regular (Normal)
    --font-weight-medium: 500; // Medium
    --font-weight-semi-bold: 600; // Semi-bold
    --font-weight-bold: 700; // Bold
    --font-weight-extra-bold: 800; // Extra Bold
    --font-weight-black: 900; // Black (Heaviest)

    @include breakpoint-up(mobile) {
        /* H1 */
        --font-size-h1: 1.75rem;
        --font-weight-h1: 600;
        --font-color-h1: var(--dark-grey);
        --line-height-h1: 1.15;

        /* H2 */
        --font-size-h2: 1.35rem;
        --font-weight-h2: 600;
        --font-color-h2: var(--dark-grey);
        --line-height-h2: 1.15;

        /* H3 */
        --font-size-h3: 1.25rem;
        --font-weight-h3: 600;
        --font-color-h3: var(--dark-grey);
        --line-height-h3: 1.15;

        /* H4 */
        --font-size-h4: 1.15rem;
        --font-weight-h4: 600;
        --font-color-h4: var(--dark-grey);
        --line-height-h4: 1.15;

        /* H5 */
        --font-size-h5: 1rem;
        --font-weight-h5: 600;
        --font-color-h5: var(--dark-grey);
        --line-height-h5: 1.15;

        /* body1 */
        --font-size-body1: 1rem;
        --font-weight-body1: 400;
        --font-color-body1: var(--dark-grey);
        --line-height-body1: 1.45;

        /* body2 */
        --font-size-body2: 1rem;
        --font-weight-body2: 400;
        --font-color-body2: var(--dark-grey);
        --line-height-body2: 1.3;

        /* Input */
        --font-size-input: 1rem;
        --font-weight-input: 400;
        --font-color-input: var(--dark-grey);
        --line-height-input: 1.15;

        /* Button */
        --font-size-button: 1rem;
        --font-weight-button: 500;
        --font-color-button: var(--dark-grey);
        --line-height-button: 1.15;

        /* Caption */
        --font-size-caption: 0.813rem;
        --font-weight-caption: 400;
        --font-color-caption: var(--dark-grey);
        --line-height-caption: 1.15;

        /* overline */
        --font-size-overline: 0.75rem;
        --font-weight-overline: 400;
        --font-color-overline: var(--dark-grey);
        --line-height-overline: 1.15;

        /* tag */
        --font-size-tag: 0.688rem;
        --font-weight-tag: 700;
        --font-color-tag: var(--dark-grey);
        --line-height-tag: 1.15;

        /* bold */
        --font-weight-bold: 700;
        --line-height-bold: 1.15;

        /* success */
        --font-size-success: 0.84rem;
        --font-weight-body-success: 400;
        --font-weight-time-success: 100;
        --font-weight-title-success: 900;
        --line-height-success: 1.35;

        /* pending */
        --font-size-pending: 0.84rem;
        --font-weight-pending: 400;
        --font-color-pending: var(--invalid-red);
        --line-height-pending: 1.35;

        /* error */
        --font-size-error: 0.84rem;
        --font-weight-error: 400;
        --font-color-error: var(--invalid-red);
        --line-height-error: 1.15;
    }
    /* Mobile Typography */
    @include breakpoint-down(mobile) {
        /* H1 */
        --font-size-h1: 1.5rem;
        --font-weight-h1: 700;
        --font-color-h1: var(--dark-grey);
        --line-height-h1: 1.15;

        /* H2 */
        --font-size-h2: 1.375rem;
        --font-weight-h2: 600;
        --font-color-h2: var(--dark-grey);
        --line-height-h2: 1.15;

        /* H3 */
        --font-size-h3: 1.25rem;
        --font-weight-h3: 700;
        --font-color-h3: var(--dark-grey);
        --line-height-h3: 1.15;

        /* H4 */
        --font-size-h4: 1.125rem;
        --font-weight-h4: 500;
        --font-color-h4: var(--dark-grey);
        --line-height-h4: 1.15;

        /* H5 */
        --font-size-h5: 1rem;
        --font-weight-h5: 700;
        --font-color-h5: var(--dark-grey);
        --line-height-h5: 1.5;

        /* body1 */
        --font-size-body1: 1rem;
        --font-weight-body1: 400;
        --font-color-body1: var(--dark-grey);
        --line-height-body1: 1.45;

        /* body2 */
        --font-size-body2: 0.875rem;
        --font-weight-body2: 400;
        --font-color-body2: var(--dark-grey);
        --line-height-body2: 1.3;

        /* Button */
        --font-size-button: 1rem;
        --font-weight-button: 500;
        --font-color-button: var(--dark-grey);
        --line-height-button: 1.15;

        /* Input */
        --font-size-input: 0.938rem;
        --font-weight-input: 400;
        --font-color-input: var(--dark-grey);
        --line-height-input: 1.15;

        /* Caption */
        --font-size-caption: 0.813rem;
        --font-weight-caption: 400;
        --font-color-caption: var(--dark-grey);
        --line-height-caption: 1.15;

        /* overline */
        --font-size-overline: 0.75rem;
        --font-weight-overline: 400;
        --font-color-overline: var(--dark-grey);
        --line-height-overline: 1.15;

        /* tag */
        --font-size-tag: 0.688rem;
        --font-weight-tag: 700;
        --font-color-tag: var(--dark-grey);
        --line-height-tag: 1.15;

        /* bold */
        --font-weight-bold: 600;
        --line-height-bold: 1.35;

        /* success */
        --font-size-success: 0.84rem;
        --font-weight-body-success: 400;
        --font-weight-time-success: 100;
        --font-weight-title-success: 900;
        --line-height-success: 1.35;

        /* pending */
        --font-size-pending: 0.84rem;
        --font-weight-pending: 400;
        --font-color-pending: var(--invalid-red);
        --line-height-pending: 1.35;

        /* error */
        --font-size-error: 0.84rem;
        --font-weight-error: 400;
        --font-color-error: var(--invalid-red);
        --line-height-error: 1.35;
    }
}
.h1 {
    font-size: var(--font-size-h1);
    font-weight: var(--font-weight-h1);
    color: var(--font-color-h1);
    line-height: var(--line-height-h1);
}

.h2 {
    font-size: var(--font-size-h2);
    font-weight: var(--font-weight-h2);
    color: var(--font-color-h2);
    line-height: var(--line-height-h2);
}

.h3 {
    font-size: var(--font-size-h3);
    font-weight: var(--font-weight-h3);
    color: var(--font-color-h3);
    line-height: var(--line-height-h3);
}

.h4 {
    font-size: var(--font-size-h4);
    font-weight: var(--font-weight-h4);
    color: var(--font-color-h4);
    line-height: var(--line-height-h4);
}

.h5 {
    font-size: var(--font-size-h5);
    font-weight: var(--font-weight-h5);
    color: var(--font-color-h5);
    line-height: var(--line-height-h5);
}

.body1 {
    font-size: var(--font-size-body1);
    font-weight: var(--font-weight-body1);
    color: var(--font-color-body1);
    line-height: var(--line-height-body1);
}

.body2 {
    font-size: var(--font-size-body2);
    font-weight: var(--font-weight-body2);
    color: var(--font-color-body2);
    line-height: var(--line-height-body2);
}

.input {
    font-size: var(--font-size-input);
    font-weight: var(--font-weight-input);
    color: var(--font-color-input);
    line-height: var(--line-height-input);
}

.button {
    font-size: var(--font-size-button);
    font-weight: var(--font-weight-button);
    color: var(--font-color-button);
    line-height: var(--line-height-button);
}

.caption {
    font-size: var(--font-size-caption);
    font-weight: var(--font-weight-caption);
    color: var(--font-color-caption);
    line-height: var(--line-height-caption);
}

.overline {
    font-size: var(--font-size-overline);
    font-weight: var(--font-weight-overline);
    color: var(--font-color-overline);
    line-height: var(--line-height-overline);
}

.tag {
    font-size: var(--font-size-tag);
    font-weight: var(--font-weight-tag);
    color: var(--font-color-tag);
    line-height: var(--line-height-tag);
}

.bold {
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-bold);
}

.error {
    font-size: var(--font-size-error);
    font-weight: var(--font-weight-error);
    color: var(--font-color-error);
    line-height: var(--line-height-error);
}
