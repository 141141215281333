@use '@angular/material' as mat;

@mixin color($theme) {
    .toolbar-theme {
        background: var(--primary-bg);
        color: mat.get-theme-color($theme, on-primary);
        justify-content: space-between;
    }
}

@mixin typography($theme) {
    .toolbar-theme {
        color: var(--forest-green);
        font-size: var(--font-size-xl);
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.1px; // Adjusted for clarity
    }
}

@mixin theme($theme) {
    @if mat.theme-has($theme, color) {
        @include color($theme);
    }

    @if mat.theme-has($theme, typography) {
        @include typography($theme);
    }
}
