@use '@angular/material' as mat;

@import '/public/mixings/mixings.scss';

@mixin color($theme) {
    .privacy-data-theme {
        background-color: var(--white-base);
        height: 100%;
        border: 0.063rem solid var(--primary-text-color);
        @include breakpoint-up(min-desktop) {
            width: 75%;
        }

        @include breakpoint-down(mobile) {
            width: unset;
        }
    }
    .mat-mdc-card-header {
        padding: 1rem;
    }
}

@mixin typography($theme) {
    .privacy-data-theme {
        .mat-mdc-card-title {
            font-size: var(--font-size-xl);
            font-weight: var(--font-weight-regular);
        }

        p {
            font-size: var(--font-size-md);
            font-weight: var(--font-weight-regular);
            color: var(--forest-green);

            .highlighted-text {
                font-weight: var(--font-weight-regular);
                font-weight: var(--font-weight-semi-bold);
            }

            .data-policy-url {
                font-weight: var(--font-weight-regular);
                font-weight: var(--font-weight-semi-bold);
            }
        }
    }
}

@mixin theme($theme) {
    @if mat.theme-has($theme, color) {
        @include color($theme);
    }

    @if mat.theme-has($theme, typography) {
        @include typography($theme);
    }
}
