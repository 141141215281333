@use '@angular/material' as mat;
@import '/public/mixings/mixings.scss';

@mixin chatbot-color($theme) {
    .chatbot-theme {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        height: 100%;
        @include breakpoint-up(min-desktop) {
            width: 75%;
        }

        @include breakpoint-down(mobile) {
            width: unset;
        }
        .chatbot-container {
            justify-content: space-between;
            background: var(--white-base);
            height: 100%;
            border: 0.063rem solid var(--primary-text-color);

            .chatbot-header {
                display: flex;
                flex-direction: column;
                justify-content: center;

                .chatbot-subtitle-primary {
                    margin-top: 0.75rem;
                }

                .chatbot-subtitle-secondary {
                    margin-top: 1rem;
                }
            }

            .chatbot-divider {
                border-color: var(--primary-bg);
                margin: 2rem 0rem;
            }
            .chatbot-chat-box-content {
                height: 100%;
                display: block;
                max-height: calc(100vh - 14.8rem);
                // relative to custom-snackbar
                position: relative;

                &.isSuggestionsVisible {
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                }

                overflow-y: auto;
                .chatbot-chat-box-suggestion-container {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    gap: 0.5rem;
                    cursor: pointer;
                    &.isSuggestionEmpty {
                        display: none;
                    }
                    .chatbot-chat-box-suggestion {
                        border: 0.063rem solid var(--primary-bg);
                        border-radius: 0.75rem;
                        padding: 1rem 0.5rem;
                        display: flex;
                        justify-content: center;

                        p {
                            margin: 0;
                        }
                    }
                }
            }
        }

        .chatbot-input-container {
            border: 0.063rem solid var(--primary-text-color);
            background: var(--white-base);
            .chatbot-form {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 0.2rem;
                .mat-mdc-form-field {
                    width: 100%;
                    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
                        background-color: var(--primary-bg);
                    }

                    .chatbot-input {
                        background-color: var(--primary-bg);
                    }

                    .chatbot-microphone-button {
                        color: var(--vibrant-blue);
                    }
                    .mdc-text-field--filled:not(
                            .mdc-text-field--disabled
                        ).mdc-text-field--focused
                        .mdc-floating-label {
                        color: var(--black-base);
                    }

                    .mdc-text-field--filled:not(.mdc-text-field--disabled)
                        .mdc-line-ripple::after {
                        border-bottom-color: var(--vibrant-blue);
                    }

                    .mdc-text-field--filled:not(.mdc-text-field--disabled)
                        .mdc-line-ripple::before {
                        border-bottom-color: var(--vibrant-blue);
                    }
                }
            }
        }
    }

    .chatbot-style-dashboard {
        @include breakpoint-up(min-desktop) {
            width: unset;
        }
    }

    .mat-mdc-form-field-focus-overlay {
        background-color: var(--primary-bg);
    }

    .mdc-text-field--filled.mdc-text-field--disabled {
        background-color: var(--primary-bg);
    }

    .mat-mdc-form-field-subscript-wrapper,
    .mat-mdc-form-field-bottom-align::before {
        display: none;
    }

    .chatbot-microphone-button {
        background: var(--primary-bg);
        color: var(--vibrant-blue);
    }
}

@mixin typography($theme) {
    .chatbot-theme {
        .chatbot-container {
            .chatbot-header {
                text-align: center;
                .chatbot-title {
                    color: var(--forest-green);
                    font-size: var(--font-size-xxl-plus);
                    font-weight: var(--font-weight-regular);

                    span {
                        color: var(--vibrant-blue);
                    }
                }
                .chatbot-subtitle-primary {
                    color: var(--forest-green);
                    font-size: var(--font-size-xl);
                    font-weight: var(--font-weight-regular);
                }

                .chatbot-subtitle-secondary {
                    color: var(--forest-green);
                    font-size: var(--font-size-md);
                    font-weight: var(--font-weight-regular);
                }

                .chatbot-subtitle-tertiary {
                    color: var(--forest-green);
                    font-size: var(--font-size-md);
                    font-weight: var(--font-weight-semi-bold);
                }
            }
            .chatbot-chat-box-content {
                color: var(--vibrant-blue);
                font-size: var(--font-size-md);
                font-weight: var(--font-weight-semi-bold);
                text-align: center;
            }
        }
    }
}

@mixin chatbot-theme($theme) {
    @if mat.theme-has($theme, color) {
        @include chatbot-color($theme);
    }

    @if mat.theme-has($theme, typography) {
        @include typography($theme);
    }
}
