@use '@angular/material' as mat;

@mixin color($theme) {
    .snackbar-theme {
        .mat-mdc-snackbar-surface {
            background-color: var(--forest-green);
        }
    }
}

@mixin typography($theme) {
    .snackbar-theme {
        span {
            color: var(--white-base);
            font-size: var(--font-size-xs);
            font-weight: var(--font-weight-semi-bold);
        }
    }
}

@mixin theme($theme) {
    @if mat.theme-has($theme, color) {
        @include color($theme);
    }

    @if mat.theme-has($theme, typography) {
        @include typography($theme);
    }
}
