@use '@angular/material' as mat;
@import '/public/mixings/mixings.scss';

@mixin color($theme) {
    .support-and-feedback-theme {
        border: 0.063rem solid var(--primary-text-color);

        background-color: var(--white-base);

        height: 100%;

        @include breakpoint-up(min-desktop) {
            width: 75%;
        }

        @include breakpoint-down(mobile) {
            width: unset;
        }

        .support-header {
            .support-title {
                margin-bottom: 0.5rem;
            }
        }
        .feedback-card-content {
            @include breakpoint-down(mobile) {
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: space-between;
            }
            .mdc-text-field--filled:not(.mdc-text-field--disabled) {
                background-color: var(--primary-bg);
                .mdc-text-field__input {
                    caret-color: var(--vibrant-blue);
                }

                &.mdc-text-field--focused .mdc-floating-label {
                    color: var(--black-base);
                    font-weight: var(--font-weight-regular);
                }

                .mdc-line-ripple {
                    &::before {
                        border-bottom-color: var(--vibrant-blue);
                    }
                    &::after {
                        border-bottom-color: var(--vibrant-blue);
                    }
                }
            }

            .button-container {
                display: flex;
                justify-content: flex-end;
                margin-top: 0.938rem;

                button {
                    width: 9.063rem;
                    height: 2.5rem;

                    @include breakpoint-down(mobile) {
                        width: 100%;
                    }
                }
            }
        }

        .mat-mdc-raised-button:not(:disabled) {
            background-color: var(--vibrant-blue);
        }
    }
}

@mixin typography($theme) {
    .support-and-feedback-theme {
        .support-header {
            .support-title {
                color: var(--forest-green);
                font-size: var(--font-size-xl);
                font-weight: var(--font-weight-regular);
            }
            .support-subtitle {
                color: var(--forest-green);
                font-size: var(--font-size-md);
                font-weight: var(--font-weight-regular);
            }
        }
        .feedback-card-content {
            .mat-mdc-form-field {
                width: 100%;
            }

            .button-container {
                button {
                    font-weight: var(--font-weight-medium);
                }
            }
        }
    }
}

@mixin theme($theme) {
    @if mat.theme-has($theme, color) {
        @include color($theme);
    }

    @if mat.theme-has($theme, typography) {
        @include typography($theme);
    }
}
