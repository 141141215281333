@use '@angular/material' as mat;
@import '/public/mixings/mixings.scss';

@mixin sidenav-color($theme) {
    .sidenav {
        background-color: var(--white-base);
        border-radius: 0rem;
        display: flex;
        flex-direction: column;

        .mat-drawer-inner-container {
            display: flex;
            flex-direction: column;

            mat-nav-list a {
                color: var(--forest-green);
            }

            .sidenav-header {
                display: flex;
                justify-content: space-between;
                padding: 0.75rem 0.5rem;

                img {
                    padding-right: 0.5rem;
                }
            }

            .sidenav-divider {
                margin: 0.5rem 1rem;
            }

            .profile-container {
                display: flex;
                align-items: center;
                padding: 0.5rem 1rem;
                p {
                    margin: 0;
                }
            }

            .menu-side-icon {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border-radius: 50%;
                width: 2.5rem;
                height: 2.5rem;
                line-height: 1.5rem;
                transition:
                    background-color 0.3s,
                    width 0.3s,
                    height 0.3s;

                &:hover {
                    background-color: var(--hover-overlay);
                }

                &:active {
                    background-color: var(--active-overlay);
                }
            }
        }

        .profile-sidenav-container {
            padding: 0rem 1rem;
        }

        .version-container {
            padding: 1rem;
            margin-top: auto;
        }
        // in case you keep side nav open
        // .mdc-list-item:focus::before {
        //     background: transparent;
        // }
    }

    .mat-drawer-content {
        background: var(--primary-bg);
    }

    .mat-drawer-content {
        background: var(--primary-bg);
    }
}

@mixin typography($theme) {
    .mdc-list-item__primary-text {
        color: var(--forest-green);
        font-size: var(--font-size-md);
        font-weight: var(--font-weight-regular);
        line-height: 1rem;
    }
}

@mixin sidenav-theme($theme) {
    @if mat.theme-has($theme, color) {
        @include sidenav-color($theme);
    }

    @if mat.theme-has($theme, typography) {
        @include typography($theme);
    }
}
