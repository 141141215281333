@use '@angular/material' as mat;

@mixin color($theme) {
    .source-theme {
        background-color: var(--white-base);

        .source-divider {
            border-top-color: var(--cloud-blue);
        }

        .mat-mdc-dialog-title {
            padding: 0.5rem;
        }

        h2 {
            margin: 0rem;
        }

        p {
            margin: 0rem;
            padding: 0.5rem;
        }

        .source-container {
            padding: 1rem;
            word-wrap: break-word;

            &.isAllowedAction {
                cursor: pointer;
            }

            &.isDownloading {
                cursor: not-allowed;
            }

            .source-link {
                position: relative;
                border-bottom: 0.063rem solid;
                transition: color 0.3s;

                &.isDownloading {
                    color: var(--gray-base);
                    pointer-events: none;

                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: 0.038rem;
                        background: linear-gradient(
                            90deg,
                            var(--vibrant-blue) 0%,
                            var(--cloud-blue) 50%,
                            var(--vibrant-blue) 100%
                        );
                        background-size: 200% 100%;
                        animation: wave 1.5s infinite;
                    }
                }
            }
        }
    }
}

@mixin typography($theme) {
    .source-theme {
        h2 {
            font-size: var(--font-size-xl);
            font-weight: var(--font-weight-regular);
            text-align: center;
        }

        p {
            text-align: center;
            font-size: var(--font-size-md);
            font-weight: var(--font-weight-regular);
            color: var(--forest-green);
        }
        .source-container {
            .source-link {
                font-family: Saans;
                font-size: var(--font-size-md);
                font-weight: var(--font-weight-regular);
                color: var(--vibrant-blue);
            }
        }
    }
}

@mixin theme($theme) {
    @if mat.theme-has($theme, color) {
        @include color($theme);
    }

    @if mat.theme-has($theme, typography) {
        @include typography($theme);
    }
}

@keyframes wave {
    from {
        background-position: 200% 0;
    }
    to {
        background-position: -200% 0;
    }
}
