@import '../mixings/mixings';

:root {
    /* Desktop Styling */
    @include breakpoint-up(mobile) {
        /* Start Of Padding */
        /* All Sides */
        --p-0: 0rem;
        --p-4: 0.25rem;
        --p-6: 0.375rem;
        --p-8: 0.5rem;
        --p-12: 0.75rem;
        --p-14: 0.875rem;
        --p-16: 1rem;
        --p-20: 1.25rem;
        --p-24: 1.5rem;
        --p-28: 1.75rem;
        --p-30: 1.875rem;
        --p-32: 2rem;
        --p-40: 2.5rem;
        --p-48: 3rem;
        --p-64: 4rem;
        /* Left Padding */
        --pl-0: 0rem;
        --pl-4: 0.25rem;
        --pl-6: 0.375rem;
        --pl-8: 0.5rem;
        --pl-12: 0.75rem;
        --pl-14: 0.875rem;
        --pl-16: 1rem;
        --pl-20: 1.25rem;
        --pl-24: 1.5rem;
        --pl-28: 1.75rem;
        --pl-30: 1.875rem;
        --pl-32: 2rem;
        --pl-40: 2.5rem;
        --pl-48: 3rem;
        --pl-64: 4rem;
        /* Right Padding */
        --pr-0: 0rem;
        --pr-4: 0.25rem;
        --pr-6: 0.375rem;
        --pr-8: 0.5rem;
        --pr-12: 0.75rem;
        --pr-14: 0.875rem;
        --pr-16: 1rem;
        --pr-20: 1.25rem;
        --pr-24: 1.5rem;
        --pr-28: 1.75rem;
        --pr-30: 1.875rem;
        --pr-32: 2rem;
        --pr-40: 2.5rem;
        --pr-48: 3rem;
        --pr-64: 4rem;
        /* Bottom Padding */
        --pb-0: 0rem;
        --pb-4: 0.25rem;
        --pb-6: 0.375rem;
        --pb-8: 0.5rem;
        --pb-12: 0.75rem;
        --pb-14: 0.875rem;
        --pb-16: 1rem;
        --pb-20: 1.25rem;
        --pb-24: 1.5rem;
        --pb-28: 1.75rem;
        --pb-30: 1.875rem;
        --pb-32: 2rem;
        --pb-40: 2.5rem;
        --pb-48: 3rem;
        --pb-64: 4rem;
        /* Top Padding */
        --pt-0: 0rem;
        --pt-4: 0.25rem;
        --pt-6: 0.375rem;
        --pt-8: 0.5rem;
        --pt-12: 0.75rem;
        --pt-14: 0.875rem;
        --pt-16: 1rem;
        --pt-20: 1.25rem;
        --pt-24: 1.5rem;
        --pt-28: 1.75rem;
        --pt-30: 1.875rem;
        --pt-32: 2rem;
        --pt-40: 2.5rem;
        --pt-48: 3rem;
        --pt-64: 4rem;
        /* END OF Padding */
        /* START OF Margin */
        --mn-1: -1rem;
        --m-0: 0rem;
        --m-4: 0.25rem;
        --m-6: 0.375rem;
        --m-8: 0.5rem;
        --m-12: 0.75rem;
        --m-14: 0.875rem;
        --m-16: 1rem;
        --m-20: 1.25rem;
        --m-24: 1.5rem;
        --m-28: 1.75rem;
        --m-30: 1.875rem;
        --m-32: 2rem;
        --m-40: 2.5rem;
        --m-48: 3rem;
        --m-64: 4rem;
        /* Left Margin */
        --ml-0: 0rem;
        --ml-4: 0.25rem;
        --ml-6: 0.375rem;
        --ml-8: 0.5rem;
        --ml-12: 0.75rem;
        --ml-14: 0.875rem;
        --ml-16: 1rem;
        --ml-20: 1.25rem;
        --ml-24: 1.5rem;
        --ml-28: 1.75rem;
        --ml-30: 1.875rem;
        --ml-32: 2rem;
        --ml-40: 2.5rem;
        --ml-48: 3rem;
        --ml-64: 4rem;
        /* Right Margin */
        --mr-0: 0rem;
        --mr-4: 0.25rem;
        --mr-6: 0.375rem;
        --mr-8: 0.5rem;
        --mr-12: 0.75rem;
        --mr-14: 0.875rem;
        --mr-16: 1rem;
        --mr-20: 1.25rem;
        --mr-24: 1.5rem;
        --mr-28: 1.75rem;
        --mr-30: 1.875rem;
        --mr-32: 2rem;
        --mr-40: 2.5rem;
        --mr-48: 3rem;
        --mr-64: 4rem;
        /* Bottom Margin */
        --mb-0: 0rem;
        --mb-4: 0.25rem;
        --mb-6: 0.375rem;
        --mb-8: 0.5rem;
        --mb-12: 0.75rem;
        --mb-14: 0.875rem;
        --mb-16: 1rem;
        --mb-20: 1.25rem;
        --mb-24: 1.5rem;
        --mb-28: 1.75rem;
        --mb-30: 1.875rem;
        --mb-32: 2rem;
        --mb-40: 2.5rem;
        --mb-48: 3rem;
        --mb-64: 4rem;
        /* Top Margin */
        --mt-0: 0rem;
        --mt-4: 0.25rem;
        --mt-6: 0.375rem;
        --mt-8: 0.5rem;
        --mt-12: 0.75rem;
        --mt-14: 0.875rem;
        --mt-16: 1rem;
        --mt-20: 1.25rem;
        --mt-24: 1.5rem;
        --mt-28: 1.75rem;
        --mt-30: 1.875rem;
        --mt-32: 2rem;
        --mt-40: 2.5rem;
        --mt-48: 3rem;
        --mt-64: 4rem;
        /* END OF MARGIN */
        /* START OF Border Radius */
        --br-0: 0rem;
        --br-4: 0.25rem;
        --br-6: 0.375rem;
        --br-8: 0.5rem;
        --br-12: 0.75rem;
        --br-14: 0.875rem;
        --br-16: 1rem;
        --br-20: 1.25rem;
        --br-24: 1.5rem;
        --br-28: 1.75rem;
        --br-30: 1.875rem;
        --br-32: 2rem;
        --br-40: 2.5rem;
        --br-48: 3rem;
        --br-64: 4rem;
        /* END OF Border Radius */
    }
    /* Mobile Styling */
    @include breakpoint-down(mobile) {
        /* Start Of Padding */
        /* All Sides */
        --p-0: 0rem;
        --p-4: 0.25rem;
        --p-6: 0.375rem;
        --p-8: 0.5rem;
        --p-12: 0.75rem;
        --p-14: 0.875rem;
        --p-16: 1rem;
        --p-20: 1.25rem;
        --p-24: 1.5rem;
        --p-28: 1.75rem;
        --p-30: 1.875rem;
        --p-32: 2rem;
        --p-40: 2.5rem;
        --p-48: 3rem;
        --p-64: 4rem;
        /* Left Padding */
        --pl-0: 0rem;
        --pl-4: 0.25rem;
        --pl-6: 0.375rem;
        --pl-8: 0.5rem;
        --pl-12: 0.75rem;
        --pl-14: 0.875rem;
        --pl-16: 1rem;
        --pl-20: 1.25rem;
        --pl-24: 1.5rem;
        --pl-28: 1.75rem;
        --pl-30: 1.875rem;
        --pl-32: 2rem;
        --pl-40: 2.5rem;
        --pl-48: 3rem;
        --pl-64: 4rem;
        /* Right Padding */
        --pr-0: 0rem;
        --pr-4: 0.25rem;
        --pr-6: 0.375rem;
        --pr-8: 0.5rem;
        --pr-12: 0.75rem;
        --pr-14: 0.875rem;
        --pr-16: 1rem;
        --pr-20: 1.25rem;
        --pr-24: 1.5rem;
        --pr-28: 1.75rem;
        --pr-30: 1.875rem;
        --pr-32: 2rem;
        --pr-40: 2.5rem;
        --pr-48: 3rem;
        --pr-64: 4rem;
        /* Bottom Padding */
        --pb-0: 0rem;
        --pb-4: 0.25rem;
        --pb-6: 0.375rem;
        --pb-8: 0.5rem;
        --pb-12: 0.75rem;
        --pb-14: 0.875rem;
        --pb-16: 1rem;
        --pb-20: 1.25rem;
        --pb-24: 1.5rem;
        --pb-28: 1.75rem;
        --pb-30: 1.875rem;
        --pb-32: 2rem;
        --pb-40: 2.5rem;
        --pb-48: 3rem;
        --pb-64: 4rem;
        /* Top Padding */
        --pt-0: 0rem;
        --pt-4: 0.25rem;
        --pt-6: 0.375rem;
        --pt-8: 0.5rem;
        --pt-12: 0.75rem;
        --pt-14: 0.875rem;
        --pt-16: 1rem;
        --pt-20: 1.25rem;
        --pt-24: 1.5rem;
        --pt-28: 1.75rem;
        --pt-30: 1.875rem;
        --pt-32: 2rem;
        --pt-40: 2.5rem;
        --pt-48: 3rem;
        --pt-64: 4rem;
        /* END OF Padding */
        /* START OF Margin */
        --mn-1: -1rem;
        --m-0: 0rem;
        --m-4: 0.25rem;
        --m-6: 0.375rem;
        --m-8: 0.5rem;
        --m-12: 0.75rem;
        --m-14: 0.875rem;
        --m-16: 1rem;
        --m-20: 1.25rem;
        --m-24: 1.5rem;
        --m-28: 1.75rem;
        --m-30: 1.875rem;
        --m-32: 2rem;
        --m-40: 2.5rem;
        --m-48: 3rem;
        --m-64: 4rem;
        /* Left Margin */
        --ml-0: 0rem;
        --ml-4: 0.25rem;
        --ml-6: 0.375rem;
        --ml-8: 0.5rem;
        --ml-12: 0.75rem;
        --ml-14: 0.875rem;
        --ml-16: 1rem;
        --ml-20: 1.25rem;
        --ml-24: 1.5rem;
        --ml-28: 1.75rem;
        --ml-30: 1.875rem;
        --ml-32: 2rem;
        --ml-40: 2.5rem;
        --ml-48: 3rem;
        --ml-64: 4rem;
        /* Right Margin */
        --mr-0: 0rem;
        --mr-4: 0.25rem;
        --mr-6: 0.375rem;
        --mr-8: 0.5rem;
        --mr-12: 0.75rem;
        --mr-14: 0.875rem;
        --mr-16: 1rem;
        --mr-20: 1.25rem;
        --mr-24: 1.5rem;
        --mr-28: 1.75rem;
        --mr-30: 1.875rem;
        --mr-32: 2rem;
        --mr-40: 2.5rem;
        --mr-48: 3rem;
        --mr-64: 4rem;
        /* Bottom Margin */
        --mb-0: 0rem;
        --mb-4: 0.25rem;
        --mb-6: 0.375rem;
        --mb-8: 0.5rem;
        --mb-12: 0.75rem;
        --mb-14: 0.875rem;
        --mb-16: 1rem;
        --mb-20: 1.25rem;
        --mb-24: 1.5rem;
        --mb-28: 1.75rem;
        --mb-30: 1.875rem;
        --mb-32: 2rem;
        --mb-40: 2.5rem;
        --mb-48: 3rem;
        --mb-64: 4rem;
        /* Top Margin */
        --mt-0: 0rem;
        --mt-4: 0.25rem;
        --mt-6: 0.375rem;
        --mt-8: 0.5rem;
        --mt-12: 0.75rem;
        --mt-14: 0.875rem;
        --mt-16: 1rem;
        --mt-20: 1.25rem;
        --mt-24: 1.5rem;
        --mt-28: 1.75rem;
        --mt-30: 1.875rem;
        --mt-32: 2rem;
        --mt-40: 2.5rem;
        --mt-48: 3rem;
        --mt-64: 4rem;
        /* END OF MARGIN */
        /* START OF Border Radius */
        --br-0: 0rem;
        --br-4: 0.25rem;
        --br-6: 0.375rem;
        --br-8: 0.5rem;
        --br-12: 0.75rem;
        --br-14: 0.875rem;
        --br-16: 1rem;
        --br-20: 1.25rem;
        --br-24: 1.5rem;
        --br-28: 1.75rem;
        --br-30: 1.875rem;
        --br-32: 2rem;
        --br-40: 2.5rem;
        --br-48: 3rem;
        --br-64: 4rem;
        /* END OF Border Radius */
    }
}
/* Padding Classes */
.p-0 {
    padding: var(--p-0);
}
.p-4 {
    padding: var(--p-4);
}
.p-6 {
    padding: var(--p-6);
}
.p-8 {
    padding: var(--p-8);
}
.p-12 {
    padding: var(--p-12);
}
.p-14 {
    padding: var(--p-14);
}
.p-16 {
    padding: var(--p-16);
}
.p-20 {
    padding: var(--p-20);
}
.p-24 {
    padding: var(--p-24);
}
.p-28 {
    padding: var(--p-28);
}
.p-30 {
    padding: var(--p-30);
}
.p-32 {
    padding: var(--p-32);
}
.p-40 {
    padding: var(--p-40);
}
.p-48 {
    padding: var(--p-48);
}
.p-64 {
    padding: var(--p-64);
}
.pt-0 {
    padding-top: var(--pt-0);
}
.pt-4 {
    padding-top: var(--pt-4);
}
.pt-6 {
    padding-top: var(--pt-6);
}
.pt-8 {
    padding-top: var(--pt-8);
}
.pt-12 {
    padding-top: var(--pt-12);
}
.pt-14 {
    padding-top: var(--pt-14);
}
.pt-16 {
    padding-top: var(--pt-16);
}
.pt-20 {
    padding-top: var(--pt-20);
}
.pt-24 {
    padding-top: var(--pt-24);
}
.pt-28 {
    padding-top: var(--pt-28);
}
.pt-30 {
    padding-top: var(--pt-30);
}
.pt-32 {
    padding-top: var(--pt-32);
}
.pt-40 {
    padding-top: var(--pt-40);
}
.pt-48 {
    padding-top: var(--pt-48);
}
.pt-64 {
    padding-top: var(--pt-64);
}
.pr-0 {
    padding-right: var(--pr-0);
}
.pr-4 {
    padding-right: var(--pr-4);
}
.pr-6 {
    padding-right: var(--pr-6);
}
.pr-8 {
    padding-right: var(--pr-8);
}
.pr-12 {
    padding-right: var(--pr-12);
}
.pr-14 {
    padding-right: var(--pr-14);
}
.pr-16 {
    padding-right: var(--pr-16);
}
.pr-20 {
    padding-right: var(--pr-20);
}
.pr-24 {
    padding-right: var(--pr-24);
}
.pr-28 {
    padding-right: var(--pr-28);
}
.pr-30 {
    padding-right: var(--pr-30);
}
.pr-32 {
    padding-right: var(--pr-32);
}
.pr-40 {
    padding-right: var(--pr-40);
}
.pr-48 {
    padding-right: var(--pr-48);
}
.pr-64 {
    padding-right: var(--pr-64);
}
.pb-0 {
    padding-bottom: var(--pb-0);
}
.pb-4 {
    padding-bottom: var(--pb-4);
}
.pb-6 {
    padding-bottom: var(--pb-6);
}
.pb-8 {
    padding-bottom: var(--pb-8);
}
.pb-12 {
    padding-bottom: var(--pb-12);
}
.pb-14 {
    padding-bottom: var(--pb-14);
}
.pb-16 {
    padding-bottom: var(--pb-16);
}
.pb-20 {
    padding-bottom: var(--pb-20);
}
.pb-24 {
    padding-bottom: var(--pb-24);
}
.pb-28 {
    padding-bottom: var(--pb-28);
}
.pb-30 {
    padding-bottom: var(--pb-30);
}
.pb-32 {
    padding-bottom: var(--pb-32);
}
.pb-40 {
    padding-bottom: var(--pb-40);
}
.pb-48 {
    padding-bottom: var(--pb-48);
}
.pb-64 {
    padding-bottom: var(--pb-64);
}
.pl-0 {
    padding-left: var(--pl-0);
}
.pl-4 {
    padding-left: var(--pl-4);
}
.pl-6 {
    padding-left: var(--pl-6);
}
.pl-8 {
    padding-left: var(--pl-8);
}
.pl-12 {
    padding-left: var(--pl-12);
}
.pl-14 {
    padding-left: var(--pl-14);
}
.pl-16 {
    padding-left: var(--pl-16);
}
.pl-20 {
    padding-left: var(--pl-20);
}
.pl-24 {
    padding-left: var(--pl-24);
}
.pl-28 {
    padding-left: var(--pl-28);
}
.pl-30 {
    padding-left: var(--pl-30);
}
.pl-32 {
    padding-left: var(--pl-32);
}
.pl-40 {
    padding-left: var(--pl-40);
}
.pl-48 {
    padding-left: var(--pl-48);
}
.pl-64 {
    padding-left: var(--pl-64);
}
/* Margin Classes */
.mn-1 {
    margin: var(--mn-1);
}
.m-0 {
    margin: var(--m-0);
}
.m-4 {
    margin: var(--m-4);
}
.m-6 {
    margin: var(--m-6);
}
.m-8 {
    margin: var(--m-8);
}
.m-12 {
    margin: var(--m-12);
}
.m-14 {
    margin: var(--m-14);
}
.m-16 {
    margin: var(--m-16);
}
.m-20 {
    margin: var(--m-20);
}
.m-24 {
    margin: var(--m-24);
}
.m-28 {
    margin: var(--m-28);
}
.m-30 {
    margin: var(--m-30);
}
.m-32 {
    margin: var(--m-32);
}
.m-40 {
    margin: var(--m-40);
}
.m-48 {
    margin: var(--m-48);
}
.m-64 {
    margin: var(--m-64);
}
.mt-0 {
    margin-top: var(--mt-0);
}
.mt-4 {
    margin-top: var(--mt-4);
}
.mt-6 {
    margin-top: var(--mt-6);
}
.mt-8 {
    margin-top: var(--mt-8);
}
.mt-12 {
    margin-top: var(--mt-12);
}
.mt-14 {
    margin-top: var(--mt-14);
}
.mt-16 {
    margin-top: var(--mt-16);
}
.mt-20 {
    margin-top: var(--mt-20);
}
.mt-24 {
    margin-top: var(--mt-24);
}
.mt-28 {
    margin-top: var(--mt-28);
}
.mt-30 {
    margin-top: var(--mt-30);
}
.mt-32 {
    margin-top: var(--mt-32);
}
.mt-40 {
    margin-top: var(--mt-40);
}
.mt-48 {
    margin-top: var(--mt-48);
}
.mt-64 {
    margin-top: var(--mt-64);
}
.mr-0 {
    margin-right: var(--mr-0);
}
.mr-4 {
    margin-right: var(--mr-4);
}
.mr-6 {
    margin-right: var(--mr-6);
}
.mr-8 {
    margin-right: var(--mr-8);
}
.mr-12 {
    margin-right: var(--mr-12);
}
.mr-14 {
    margin-right: var(--mr-14);
}
.mr-16 {
    margin-right: var(--mr-16);
}
.mr-20 {
    margin-right: var(--mr-20);
}
.mr-24 {
    margin-right: var(--mr-24);
}
.mr-28 {
    margin-right: var(--mr-28);
}
.mr-30 {
    margin-right: var(--mr-30);
}
.mr-32 {
    margin-right: var(--mr-32);
}
.mr-40 {
    margin-right: var(--mr-40);
}
.mr-48 {
    margin-right: var(--mr-48);
}
.mr-64 {
    margin-right: var(--mr-64);
}
.mb-0 {
    margin-bottom: var(--mb-0);
}
.mb-4 {
    margin-bottom: var(--mb-4);
}
.mb-6 {
    margin-bottom: var(--mb-6);
}
.mb-8 {
    margin-bottom: var(--mb-8);
}
.mb-12 {
    margin-bottom: var(--mb-12);
}
.mb-14 {
    margin-bottom: var(--mb-14);
}
.mb-16 {
    margin-bottom: var(--mb-16);
}
.mb-20 {
    margin-bottom: var(--mb-20);
}
.mb-24 {
    margin-bottom: var(--mb-24);
}
.mb-28 {
    margin-bottom: var(--mb-28);
}
.mb-30 {
    margin-bottom: var(--mb-30);
}
.mb-32 {
    margin-bottom: var(--mb-32);
}
.mb-40 {
    margin-bottom: var(--mb-40);
}
.mb-48 {
    margin-bottom: var(--mb-48);
}
.mb-64 {
    margin-bottom: var(--mb-64);
}
.ml-0 {
    margin-left: var(--ml-0);
}
.ml-4 {
    margin-left: var(--ml-4);
}
.ml-6 {
    margin-left: var(--ml-6);
}
.ml-8 {
    margin-left: var(--ml-8);
}
.ml-12 {
    margin-left: var(--ml-12);
}
.ml-14 {
    margin-left: var(--ml-14);
}
.ml-16 {
    margin-left: var(--ml-16);
}
.ml-20 {
    margin-left: var(--ml-20);
}
.ml-24 {
    margin-left: var(--ml-24);
}
.ml-28 {
    margin-left: var(--ml-28);
}
.ml-30 {
    margin-left: var(--ml-30);
}
.ml-32 {
    margin-left: var(--ml-32);
}
.ml-40 {
    margin-left: var(--ml-40);
}
.ml-48 {
    margin-left: var(--ml-48);
}
.ml-64 {
    margin-left: var(--ml-64);
}
/* Border-radius */
.br-0 {
    border-radius: var(--br-0);
}
.br-4 {
    border-radius: var(--br-4);
}
.br-6 {
    border-radius: var(--br-6);
}
.br-8 {
    border-radius: var(--br-8);
}
.br-12 {
    border-radius: var(--br-12);
}
.br-14 {
    border-radius: var(--br-14);
}
.br-16 {
    border-radius: var(--br-16);
}
.br-20 {
    border-radius: var(--br-20);
}
.br-24 {
    border-radius: var(--br-24);
}
.br-28 {
    border-radius: var(--br-28);
}
.br-30 {
    border-radius: var(--br-30);
}
.br-32 {
    border-radius: var(--br-32);
}
.br-40 {
    border-radius: var(--br-40);
}
.br-48 {
    border-radius: var(--br-48);
}
.br-64 {
    border-radius: var(--br-64);
}
