@use '@angular/material' as mat;

@mixin color($theme) {
    .support-and-feedback-theme {
        background-color: var(--white-base);

        .mdc-text-field--filled:not(.mdc-text-field--disabled) {
            background-color: var(--primary-bg);
            .mdc-text-field__input {
                caret-color: var(--vibrant-blue);
            }

            &.mdc-text-field--focused .mdc-floating-label {
                color: var(--black-base);
                font-weight: var(--font-weight-regular);
            }

            .mdc-line-ripple {
                &::before {
                    border-bottom-color: var(--vibrant-blue);
                }
                &::after {
                    border-bottom-color: var(--vibrant-blue);
                }
            }
        }

        .mat-mdc-raised-button:not(:disabled) {
            background-color: var(--vibrant-blue);
        }
    }
}

@mixin typography($theme) {
    .support-and-feedback-theme {
        h2 {
            font-size: var(--font-size-xl);
            font-weight: var(--font-weight-regular);
            text-align: center;
        }

        p {
            text-align: center;
            font-size: var(--font-size-md);
            font-weight: var(--font-weight-regular);
            color: var(--forest-green);
        }

        .mat-mdc-form-field {
            width: 100%;
        }

        .button-container {
            display: flex;
            justify-content: flex-end;
            margin-top: 0.938rem;

            button {
                width: 9.063rem;
                height: 2.5rem;
                font-weight: var(--font-weight-medium);
            }
        }
    }
}

@mixin theme($theme) {
    @if mat.theme-has($theme, color) {
        @include color($theme);
    }

    @if mat.theme-has($theme, typography) {
        @include typography($theme);
    }
}
