@use 'SASS:map';

:root {
    --primary-bg: #e8e8e3;

    --primary-text-color: #aa95fe;

    //Default Light Theme
    --white-base: #fff;
    --black-base: #000;
    --gray-base: #808080;
    --green-base: #008000;
    --red-base: #ff0000;

    --black-translucent: #00000093;
    --dark-charcoal: #303030;
    --muted-charcoal: #5d5c5c;

    --midnight-graphite: #262b2e;

    --warning-text-color: #856404;
    --warning-background: #fff3cd;
    --light-yellow: #ffeeba;
    --lemon-yellow: #fef387;
    --shadow-black: rgba(0, 0, 0, 0.1);
    --shadow-medium: rgba(0, 0, 0, 0.2);
    --shadow-soft: rgba(0, 0, 0, 0.3);
    --deep-night-black: #131516;
    --off-black: #111111;

    --white-smoke: #f5f5f5;

    --orange: #ff782d;
    --vibrant-orange: #ff9800;
    --ember-orange: #f2772c;

    --purple: #aa95ff;

    --header-gray-blue: #677591;
    --skeleton-light-gray: #f0f0f0;
    --skeleton-medium-gray: #e0e0e0;
    --footer-background-gray: #e7e7e7;
    --soft-light-gray: #f2f2f2;
    --footer-text-gray: #808080c5;
    --light-gray: #e8e8e3;
    --dark-blue-gray: #475465;
    --light-gray-border: #ddd;
    --soft-silver: #ccc;
    --soft-gray-border: #d0d5dd;
    --off-white-gray: #f4f5f7;
    --steel-blue-gray: #607d8b;
    --deep-shadow-gray: #313131;
    --midnight-steel-gray: #232629;
    --soft-mist-gray: rgba(203, 200, 200, 0.307);
    --pale-gray: #f3f3f3;
    --cloudy-sky-gray: #f2f4f8;
    --onyx-gray: #383d42;
    --dim-gray: #4d4d4d;
    --dark-gray: #999999;
    --outer-space-gray: #494747;
    --ash-gray: #4b4b4b;
    --olive-gray: #4a4b4a;
    --pale-silver-gray: #f1f1f1;
    --light-steel-gray: #a4a4a6;
    --pale-cloud-gray: #f8f8f8;
    --light-silver-gray: #efefef;

    --dark-jungle-green: #1c1e21;
    --forest-green: #2b3e2b;

    --deep-blue: #0d367b;
    --sky-blue: #87ceeb;
    --vibrant-blue: #4c3de3;
    --ocean-blue: #3498db;
    --bright-blue: #0095da;
    --icy-blue: #bbe8ee;
    --cloud-blue: #e2e8f2;
    --light-sky-blue: #c5eafa;
    --soft-sky-blue: #f6f8fe;
    --misty-blue: #a7e5ff;

    --pale-snow: #f9f9f9;

    --light-teal: #e0f2f1;
    --soft-teal: #b2dfdb;

    --medium-indigo: #3f51b5;

    --success: #05aa6f;
    --partial: #d9a542;
    --failed: #b81804;

    --lighter-success: #05aa70b8;
    --lighter-partial: #d9a442b4;
    --lighter-failed: #bb17016d;

    --dark-green: #006400;

    --error-text: #f9361c;

    --skeleton-charcoal-muted: rgb(23 24 24 / 79%);
    --green-playwright: #24942b;
    --green-hover: #45a049;

    --hover-overlay: rgba(0, 0, 0, 0.04);

    --active-overlay: rgba(0, 0, 0, 0.12);

    //TODO
    // .dark-theme {
    // }
}

$primaryLight: #f6f8fe;
$primary: #0095da;
$lighterGray: #e2e8f2;
$lightGray: #d0d5dd;
$gray: #a8a8a8;
$darkGray: #4a4b4a;
